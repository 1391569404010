body {
  margin: 0;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  min-height: 99.8vh;
}

#root,
.App {
  flex: 1;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logo {
  width: 200px;
}

.content {
  display: flex;
  flex: 1;
  justify-content: stretch;
}

.drawer {
  z-index: 0;
  width: 350px;
  height: 100%;
  flex: 1;
  max-width: 350px;
  justify-content: center;
}

.app_bar {
  z-index: 5;
  background: white !important;
}

.navigation {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.app_logo {
  font-weight: bold;
  font-size: 32px;
  color: black;
}

.step_label {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.step_text {
  text-align: left;
  line-height: 1.4;
  font-size: 15px;
}

.main {
  padding: 35px;
  text-align: center;
  flex: 1;
}

.question_count {
  font-weight: bold;
  font-size: 26px;
}

.question_card {
  margin-top: 25px;
  border-color: #ff0035 !important;
  background: rgba(#ff0035, 0.4) !important;
}

.question_text {
  font-weight: bold !important;
  font-size: 25px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.input {
  width: 500px;
  margin: 25px auto;
}

.icon {
  margin-right: 5px;
}

.next_question {
  margin-left: 15px;
}

.buttons {
  justify-content: center;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
}

.success_message {
  font-size: 22px;
  font-weight: bold;
}

.emoji {
  margin-top: 25px;
  font-size: 80px;
}

.token {
  width: 350px;
  word-break: break-all;
  text-overflow: clip;
  margin: 45px auto;
  border: 2px solid black;
  padding: 15px;
  background: #ff0035;
  border-radius: 5px;
}

.download_icon_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

a {
  text-decoration: none !important;
  color: black;
}

.download_icon {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
}

.download_icon:hover {
  color: #ff0035;
}

.screen_title {
  font-size: 30px;
  margin-bottom: 25px;
}

.screen_success_description {
  max-width: 550px;
  margin: 0 auto;
  font-size: 20px;
}

.form {
  width: 500px;
  display: flex;
  flex-direction: column;
}

.form_wrapper {
  margin: 50px auto;
}

.form_button {
  margin-top: 25px;
  margin-bottom: 100px;
}

.form_field {
  margin-bottom: 10px;
  width: 100%;
}

.description_details{
  margin: 25px;
  margin-top: 0px;
  font-size: 14px;
  padding: 10px;
  background-color: rgb(228, 228, 228);
}

.home_screen_text{
  font-size: 22px;
  line-height:150%;
  max-width: 1500px;
  margin: 0 auto;
}

.home_screen_rules{
  font-size: 20px;
  line-height:150%;
}

.home_screen_cdc_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.home_screen_cdc_picture img {
  width: 50%;
  height: auto;
}

.cookie_banner{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #8c8c8c;
  padding: 15px;
  color: white;
  padding-right: 50px;
}

.cookie_banner_close_button{
  height: 25px;
  width: 25px;
  position: absolute;
  top: 15px;
  right: 20px;
}

footer{
  padding: 15px;
  background-color: #ff0035;
  color: #fff;
}

.footer-href{
  color: #fff;
}

.cookie_policy{
  margin: 100px;
}